import Verticals from "@/core/enums/verticals.enum";

interface VerticalConfig {
    path: string;
    isActive: (pathname: string) => boolean;
}

type VerticalsConfig = {
    [key in Verticals]: VerticalConfig;
};

export const verticalsConfig: VerticalsConfig = {
    [Verticals.Marketing]: {
        path: "/hp/marketing",
        isActive: (pathname: string) => /^\/hp\/marketing/.test(pathname),
    },
    [Verticals.Accounting]: {
        path: "/hp/accounting",
        isActive: (pathname: string) => /^\/hp\/accounting/.test(pathname),
    },
    [Verticals.Sales]: {
        path: "/hp",
        isActive: (pathname: string) =>
            /^\/hp(\/|$)/.test(pathname) &&
            !/^\/hp\/(marketing|accounting)/.test(pathname),
    },
};

export const getDefaultVertical = (verticals: Verticals[]): Verticals => {
    const priorityOrder: Verticals[] = [
        Verticals.Sales,
        Verticals.Marketing,
        Verticals.Accounting,
    ];
    return (
        priorityOrder.find((vertical) => verticals.includes(vertical)) ||
        verticals[0]
    );
};

export const getVerticalFromPath = (
    pathname: string
): Verticals | undefined => {
    return Object.entries(verticalsConfig).find(([_, config]) =>
        config.isActive(pathname)
    )?.[0] as Verticals | undefined;
};

export const getPathForVertical = (
    vertical: Verticals,
    currentPath: string
): string => {
    const config = verticalsConfig[vertical];
    return config
        ? cleanPathname(
              `${config.path}${currentPath.replace(/^\/hp(\/marketing|\/accounting)?/, "")}`
          )
        : currentPath;
};

export const cleanPathname = (pathname: string): string => {
    return pathname
        .replace(/\/+/g, "/")
        .replace(/^\/hp\/hp/, "/hp")
        .replace(/\/$/, "");
};
