import Verticals from "@/core/enums/verticals.enum";
import { RouteInterface } from "@/core/interfaces/route.interface";
import SalesCPRoutes from "@/core/routes/candidate/sales.routes";
import AccountingRoutes from "@/core/routes/hiring-partner/accounting.routes";
import MarketingHPRoutes from "@/core/routes/hiring-partner/marketing.routes";
import SalesHPRoutes from "@/core/routes/hiring-partner/sales.routes";
import { CurrUserDetails } from "@/utils/currUserType";
import {
    getUserOS,
    isOnboarding,
    needsToFillPlacement,
    bootcamperNeedToContinue,
} from "@/utils/utilFunctions";

const onBoardingStyle = (currentUser: CurrUserDetails) => ({
    pointerEvents: isOnboarding(currentUser) ? "none" : "auto",
    filter: isOnboarding(currentUser) ? "blur(5px)" : "none",
    userSelect: isOnboarding(currentUser) ? "none" : "text",
});

export function getPath(pathname: string): string | undefined {
    if (pathname.includes("/hp/marketing/")) return "marketing";
    if (pathname.includes("/hp/accounting")) return "accounting";
    if (pathname.includes("/hp")) return "sales";
    return undefined; // If no matching path, return undefined
}

export function getRoutesByPath(
    pathname: string,
    currentUser: CurrUserDetails
) {
    const sales = filterMenuItems(SalesHPRoutes);
    const marketing = filterMenuItems(MarketingHPRoutes);
    const accounting = filterMenuItems(AccountingRoutes);
    const candidate_routes = filterCandidateRoutes(SalesCPRoutes, currentUser);

    const pathMapper: { [key: string]: RouteInterface[] } = {
        marketing: marketing,
        accounting: accounting,
        sales: sales,
    };

    const path = getPath(pathname);
    if (path === undefined) {
        return candidate_routes;
    }

    // Return corresponding routes
    return pathMapper[path];
}

export function getVerticalByPath(currentPath: string): number {
    const pathMapper: { [key: string]: number } = {
        sales: Verticals.Sales,
        accounting: Verticals.Accounting,
        marketing: Verticals.Marketing,
    };
    // Return the mapped vertical or -1 for unsupported paths
    return pathMapper[currentPath] ?? -1;
}

// Later in the same file
export {
    getUserOS,
    isOnboarding,
    needsToFillPlacement,
    bootcamperNeedToContinue,
    onBoardingStyle,
};

const filterMenuItems = (routes: Array<RouteInterface>) =>
    routes.filter((route) => route.isMenuItem);

const filterCandidateRoutes = (
    routes: Array<RouteInterface>,
    currentUser: CurrUserDetails
) =>
    routes.filter((route) => {
        if (!route.isMenuItem) return false;
        if (
            route.props?.view === "fast-tracker" &&
            currentUser?.views !== "fast-tracker"
        )
            return false;
        return true;
    });
