import * as React from "react";
import "./header-mobile.scss";
import LogoArWithText from "../../../static/images/logo-ar.svg";
import LogoEnWithText from "../../../static/images/logo-01.png";
import UserProfileCard from "../../components/Header/components/user-profile-card/UserProfileCard";
import HeaderItemsLayout from "../../components/Header/components/header-items-layout";
import { useTranslation } from "react-i18next";
import MenuLink from "./components/menu-link/menu-link";
import MenuItem from "../../components/Header/components/menu-item/menu-item";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import "@/components/atoms/dropdown-button/vertical-dropdown-button.scss";
import { UserProvider, getUser } from "@/contexts/UserContext";
import {
    bootcamperNeedToContinue,
    getUserOS,
    isOnboarding,
    needsToFillPlacement,
} from "@/utils/utilFunctions";
import JobApplicantsBanner from "../JobApplicantsBanner";
import MenuItemNotification from "./components/menu-notification/menu-notification";
import { FeatureFlag } from "@/feature-flag";
import HPOnBoardingFeedbackModal from "@/components/Modals/HPOnBoardingFeedbackModal";
import {
    ContactSupportOutlined,
    ExitToApp,
    InfoOutlined,
    SettingsOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import LocaleContext from "@/contexts/LocaleContext";
import { getRandomColor } from "./utils";
import NotificationMenu from "@/components/Header/components/menu-item/notification-menu";
import LanguageButton from "../SideBar/components/platform-actions/language-button";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedVertical } from "@/core/stores/verticals/selected-vertical.store";
import SubscriptionLabel from "../SubscriptionLabel";
import FreeTrialBanner from "../FreeTrialBanner";
import Verticals from "@/core/enums/verticals.enum";
import SalesHPRoutes from "@/core/routes/hiring-partner/sales.routes";
import MarketingHPRoutes from "@/core/routes/hiring-partner/marketing.routes";
import SalesCPRoutes from "@/core/routes/candidate/sales.routes";
import { marketing_v3_flag } from "@/helpers/feature-flag.helper";
import AccountingRoutes from "@/core/routes/hiring-partner/accounting.routes";
import useVerticalButton from "../SideBar/sidebar/hooks/useVerticalButton";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const HeaderMobile = ({
    messagesNotify,
    curatedListCount,
    jobApplicants,
    login,
    changeLang,
}) => {
    const currentUser = getUser();
    const { locale } = React.useContext(LocaleContext);
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [altColor] = React.useState(getRandomColor());
    const navigate = useNavigate();
    const selectedVertical = useSelector(
        (state) => state.selectedVertical.vertical
    );

    const enableAccountingv1 = useFeatureIsOn("enable_accounting_v1");

    const { variant } = useVerticalButton(selectedVertical);
    const dispatch = useDispatch();

    const updateSelectedVertical = (newVertical) => {
        dispatch(setSelectedVertical(newVertical));
    };
    const os = getUserOS();
    const [
        showIncompleteOnboardingMessage,
        setShowIncompleteOnboardingMessage,
    ] = useState(false);

    const logout = () => {
        document.getElementById("logout-modal").style.display = "flex";
    };
    const contact = () => {
        document.getElementById("contact-modal").style.display = "flex";
    };

    const getLogo = () => {
        if (locale === "en") {
            return LogoEnWithText;
        }
        if (locale === "ar") {
            return LogoArWithText;
        }
    };

    const sales = SalesHPRoutes.filter((route) => route.isMenuItem);
    const marketing = MarketingHPRoutes.filter((route) => route.isMenuItem);
    const accounting = AccountingRoutes.filter((route) => route.isMenuItem);
    const candidate_routes = SalesCPRoutes.filter((route) => {
        if (!route.isMenuItem) {
            return false;
        }
        if (
            route.props?.view === "fast-tracker" &&
            currentUser.views !== "fast-tracker"
        ) {
            return false;
        }
        return true;
    });
    const urls = pathname.includes("/hp/marketing/")
        ? marketing
        : pathname.includes("/hp/accounting")
          ? accounting
          : pathname.includes("/hp")
            ? sales
            : candidate_routes;

    const activeUrls =
        currentUser.name === undefined
            ? urls.filter((route) => route.isPublic)
            : urls;
    return (
        <>
            <UserProvider>
                <header className="header-container mobile-header">
                    <div className="header-logo">
                        <img
                            src={getLogo()}
                            alt="logo"
                            className="logo"
                            onClick={() => {
                                if (
                                    needsToFillPlacement(currentUser) ||
                                    bootcamperNeedToContinue(currentUser)
                                ) {
                                    document.getElementById(
                                        "you-need-to-complete-profile"
                                    ).style.display = "flex";
                                } else {
                                    switch (currentUser.views) {
                                        case "hp":
                                            return navigate("/hp/candidates");
                                        case "candidate":
                                            return navigate("/candidate/jobs", {
                                                state: {
                                                    source: "From Header",
                                                },
                                            });
                                        default:
                                            return navigate(
                                                "/candidate/ft-vetting-process"
                                            );
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="header-actions">
                        <>
                            {FeatureFlag.notification(currentUser.views) && (
                                <div
                                    className={`mobileNotfication ${isOnboarding(currentUser) && "menu-blur"}`}
                                >
                                    <NotificationMenu user={currentUser} />
                                </div>
                            )}
                        </>
                        <div className="header-action">
                            <HeaderItemsLayout
                                locale={locale}
                                isMenuOpen={isMenuOpen}
                                setIsMenuOpen={setIsMenuOpen}
                            >
                                <div className="header-top">
                                    <div className="header-action">
                                        {currentUser.name ? (
                                            <UserProfileCard
                                                locale={locale}
                                                hideProfile
                                                user={currentUser}
                                                logout={logout}
                                                contact={contact}
                                                altColor={altColor}
                                            />
                                        ) : (
                                            <Button
                                                className="filled-button"
                                                onClick={login}
                                            >
                                                {t("login.title")}
                                            </Button>
                                        )}
                                    </div>
                                    {currentUser.company ? (
                                        <SubscriptionLabel
                                            currUser={currentUser}
                                            t={t}
                                            mobile={true}
                                            clickable={true}
                                        />
                                    ) : (
                                        <></>
                                    )}

                                    {
                                        <div className="header-menu">
                                            {currentUser?.views === "hp" && (
                                                <>
                                                    <p className="header-menu__title">
                                                        {t("platform_type")}
                                                    </p>
                                                    <div
                                                        className={clsx(
                                                            "vertical-content",
                                                            {
                                                                "vertical-content--primary":
                                                                    selectedVertical ===
                                                                    Verticals.Sales,
                                                                "vertical-content--secondary":
                                                                    selectedVertical ===
                                                                    Verticals.Marketing,
                                                                "vertical-content--accent":
                                                                    selectedVertical ===
                                                                    Verticals.Accounting,
                                                            }
                                                        )}
                                                    >
                                                        <Button
                                                            className={clsx(
                                                                "vertical-buttons",
                                                                "vertical-buttons--primary",
                                                                {
                                                                    "vertical-buttons--primary--selected":
                                                                        selectedVertical ===
                                                                        Verticals.Sales,
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                updateSelectedVertical(
                                                                    Verticals.Sales
                                                                );
                                                            }}
                                                        >
                                                            {t(
                                                                "vertical.sales"
                                                            )}
                                                            <p className="vertical-buttons__content">
                                                                {t(
                                                                    "vertical.sales-content.label"
                                                                )}
                                                            </p>
                                                        </Button>
                                                        <Button
                                                            className={clsx(
                                                                "vertical-buttons",
                                                                "vertical-buttons--secondary",
                                                                {
                                                                    "vertical-buttons--secondary--selected":
                                                                        selectedVertical ===
                                                                        Verticals.Marketing,
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                if (
                                                                    pathname !==
                                                                    "/hp/marketing/candidates"
                                                                ) {
                                                                    navigate(
                                                                        "/hp/marketing/candidates"
                                                                    );
                                                                }
                                                                updateSelectedVertical(
                                                                    Verticals.Marketing
                                                                );
                                                            }}
                                                        >
                                                            {t(
                                                                "vertical.marketing"
                                                            )}
                                                            <p className="vertical-buttons__content">
                                                                {t(
                                                                    "vertical.marketing-content.label"
                                                                )}
                                                            </p>
                                                        </Button>
                                                        {enableAccountingv1 && (
                                                            <Button
                                                                className={clsx(
                                                                    "vertical-buttons",
                                                                    "vertical-buttons--accent",
                                                                    {
                                                                        "vertical-buttons--accent--selected":
                                                                            selectedVertical ===
                                                                            Verticals.Accounting,
                                                                    }
                                                                )}
                                                                onClick={() => {
                                                                    if (
                                                                        pathname !==
                                                                        "/hp/accounting/candidates"
                                                                    ) {
                                                                        navigate(
                                                                            "/hp/accounting/candidates"
                                                                        );
                                                                    }
                                                                    updateSelectedVertical(
                                                                        Verticals.Accounting
                                                                    );
                                                                }}
                                                            >
                                                                {t(
                                                                    "vertical.accounting"
                                                                )}
                                                                <p className="vertical-buttons__content">
                                                                    {t(
                                                                        "vertical.accounting-content.label"
                                                                    )}
                                                                </p>
                                                            </Button>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    }

                                    <div
                                        className={`header-menu ${isOnboarding(currentUser) && "disable-header-menu"}`}
                                    >
                                        {activeUrls.map((link, index) => {
                                            if (
                                                !(
                                                    currentUser.name ===
                                                        undefined &&
                                                    !link.isPublic
                                                )
                                            ) {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        to={
                                                            (currentUser.name ===
                                                                undefined &&
                                                                link.authRequired) ||
                                                            isOnboarding(
                                                                currentUser
                                                            ) ||
                                                            needsToFillPlacement(
                                                                currentUser
                                                            ) ||
                                                            bootcamperNeedToContinue(
                                                                currentUser
                                                            )
                                                                ? "#"
                                                                : link.path
                                                        }
                                                        isDisabled={
                                                            selectedVertical ===
                                                                Verticals.Accounting &&
                                                            !marketing_v3_flag() &&
                                                            index !== 0
                                                        }
                                                        text={`${t(link.name)}`}
                                                        testId={link.name}
                                                        active={pathname.includes(
                                                            link.path
                                                        )}
                                                        onClick={() => {
                                                            if (
                                                                isOnboarding(
                                                                    currentUser
                                                                )
                                                            ) {
                                                                setShowIncompleteOnboardingMessage(
                                                                    true
                                                                );
                                                            } else if (
                                                                needsToFillPlacement(
                                                                    currentUser
                                                                ) ||
                                                                bootcamperNeedToContinue(
                                                                    currentUser
                                                                )
                                                            ) {
                                                                document.getElementById(
                                                                    "you-need-to-complete-profile"
                                                                ).style.display =
                                                                    "flex";
                                                            } else {
                                                                if (
                                                                    currentUser.name ===
                                                                        undefined &&
                                                                    !link.isPublic
                                                                ) {
                                                                    document.getElementById(
                                                                        "login-job-modal"
                                                                    ).style.display =
                                                                        "flex";
                                                                }
                                                            }
                                                        }}
                                                        notification={
                                                            <MenuItemNotification
                                                                locale={locale}
                                                                link={link}
                                                                messagesNotify={
                                                                    messagesNotify
                                                                }
                                                                curatedListCount={
                                                                    curatedListCount
                                                                }
                                                                jobApplicants={
                                                                    jobApplicants
                                                                }
                                                                os={os}
                                                            />
                                                        }
                                                        variant={variant}
                                                    >
                                                        {<link.icon />}
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </div>
                                    <Divider
                                        sx={{
                                            marginInline: "16px",
                                            marginBlock: "16px",
                                        }}
                                    />
                                    {isOnboarding(currentUser) && (
                                        <div className="curated_list__alert">
                                            <Alert
                                                severity="info"
                                                className="info_text"
                                            >
                                                {t(
                                                    "onboarding_feature_unlock_message"
                                                )}
                                            </Alert>
                                        </div>
                                    )}
                                    {currentUser.name && (
                                        <div className="menu-item-wrapper">
                                            {currentUser.views === "hp" && (
                                                <MenuLink
                                                    to={"/hp/settings"}
                                                    text={t("settings")}
                                                    active={pathname.includes(
                                                        "/hp/settings"
                                                    )}
                                                    onClick={() => {
                                                        if (
                                                            !pathname.includes(
                                                                "/hp/settings"
                                                            )
                                                        ) {
                                                            setIsMenuOpen(
                                                                !isMenuOpen
                                                            );
                                                        }
                                                    }}
                                                    isDisabled={isOnboarding(
                                                        currentUser
                                                    )}
                                                >
                                                    <SettingsOutlined />
                                                </MenuLink>
                                            )}

                                            <Button
                                                startIcon={
                                                    <ContactSupportOutlined />
                                                }
                                                className="menu-link"
                                                onClick={contact}
                                            >
                                                {t("contact_us.title")}
                                            </Button>

                                            <LanguageButton
                                                changeLang={changeLang}
                                                isExpended={true}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="bottom-container">
                                    {currentUser.name && (
                                        <div
                                            className="bottom-item text-button-danger"
                                            onClick={logout}
                                        >
                                            <ExitToApp />
                                            {t("logout.title")}
                                        </div>
                                    )}
                                </div>
                            </HeaderItemsLayout>
                        </div>
                    </div>
                </header>
                {FeatureFlag.free_trial(
                    currentUser.views,
                    currentUser.company?.subscribed
                ) && <FreeTrialBanner locale={locale} user={currentUser} />}
                <JobApplicantsBanner />
            </UserProvider>
            {showIncompleteOnboardingMessage && (
                <HPOnBoardingFeedbackModal
                    action={() => setShowIncompleteOnboardingMessage(false)}
                    message={t("onboarding_incomplete_message")}
                    actionText={t("incomplete_action_text")}
                    modalLogo={<InfoOutlined />}
                />
            )}
        </>
    );
};
export default HeaderMobile;
