import { getUser } from "@/contexts/UserContext";
import Verticals from "@/core/enums/verticals.enum";
import { setSelectedVertical } from "@/core/stores/verticals/selected-vertical.store";
import BookSubscriptionCall from "@/pages/hp/BookSubscriptionCall";
import singletonLocalStorage from "@/utils/localStoring";
import React, { useEffect } from "react";
import { RootStateOrAny } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
    getDefaultVertical,
    getPathForVertical,
    getVerticalFromPath,
} from "./verticals-config";

type Props = {
    isVerticalRequired?: boolean;
    expectedVertical: Verticals;
    children: React.ReactNode;
};

const RequireVerticalSubscription: React.FC<Props> = (props: Props) => {
    const { children, expectedVertical, isVerticalRequired } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const currUser = getUser();
    const verticals = currUser?.company?.active_subscription?.verticals.map(
        (vertical) => vertical.id
    );

    const lastSelectedVertical = singletonLocalStorage.getItem("vertical");
    const selectedVertical = useSelector(
        (state: RootStateOrAny) => state.selectedVertical.vertical
    );

    useEffect(() => {
        // Handle the first reach to the app
        if (!selectedVertical && lastSelectedVertical) {
            dispatch(setSelectedVertical(lastSelectedVertical));
            const expectedPageToVisit = getPathForVertical(
                lastSelectedVertical,
                location.pathname
            );
            navigate(expectedPageToVisit, { replace: true });
        }
    }, [
        dispatch,
        selectedVertical,
        lastSelectedVertical,
        location.pathname,
        navigate,
    ]);

    //Handling the case when no vertical was selected, but the user have access to one or more verticals
    if (!selectedVertical && verticals && verticals.length > 0) {
        const defaultVertical = getDefaultVertical(verticals);
        dispatch(setSelectedVertical(defaultVertical));
        const expectedPageToVisit = getPathForVertical(
            defaultVertical,
            location.pathname
        );
        return <Navigate to={expectedPageToVisit} replace />;
    }
    //Handling cases when the user is trying to access the wrong path based on their last selected vertical
    const currentPathVertical = getVerticalFromPath(location.pathname);
    if (
        selectedVertical &&
        currentPathVertical &&
        Number(currentPathVertical) !== selectedVertical
    ) {
        console.log(selectedVertical, currentPathVertical);
        const expectedPageToVisit = getPathForVertical(
            selectedVertical,
            location.pathname
        );
        return <Navigate to={expectedPageToVisit} replace />;
    }

    if (currUser?.company?.free_trial) {
        if (!selectedVertical) {
            dispatch(setSelectedVertical(Verticals.Sales));
        }
        return children;
    }

    // Aahad's work. The Book subscription call keeps on flickering
    if (!currUser?.id) {
        return <></>;
    }

    // Return the BookSubscriptionCall if the verticals array is not available or empty
    if (!verticals || verticals.length === 0) {
        return <BookSubscriptionCall />;
    }

    // If the vertical is required but not included in the active subscription, show BookSubscriptionCall
    if (isVerticalRequired && !verticals.includes(expectedVertical)) {
        return <BookSubscriptionCall />;
    }

    // Return the children directly if the vertical is not required
    if (!isVerticalRequired) {
        return children;
    }

    // Check if the last selected vertical is in the active subscription
    if (lastSelectedVertical && verticals.includes(lastSelectedVertical)) {
        return children;
    }

    return children;
};

export default RequireVerticalSubscription;
