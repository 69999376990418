import React, { useMemo } from "react";
import Verticals from "@/core/enums/verticals.enum";
import { useTranslation } from "react-i18next";
import {
    CampaignOutlined,
    HandshakeOutlined,
    PaymentsOutlined,
} from "@mui/icons-material";

const useVerticalButton = (selectedVertical: Verticals) => {
    const { t } = useTranslation();

    const verticalConfig = useMemo(
        () => ({
            [Verticals.Sales]: {
                icon: <HandshakeOutlined />,
                text: t("vertical.sales"),
                variant: "primary",
            },
            [Verticals.Marketing]: {
                icon: <CampaignOutlined />,
                text: t("vertical.marketing"),
                variant: "secondary",
            },
            [Verticals.Accounting]: {
                icon: <PaymentsOutlined />,
                text: t("vertical.accounting"),
                variant: "accent",
            },
        }),
        [t]
    );

    const { icon, text, variant } =
        verticalConfig[selectedVertical as keyof typeof verticalConfig] || {};

    return { icon, buttonText: text, variant };
};
export default useVerticalButton;
