import { lazy } from "react";
import { WorkOutlineOutlined, ListOutlined } from "@material-ui/icons";
import { PeopleOutlineOutlined, RouteOutlined } from "@mui/icons-material";
import { RouteInterface } from "@/core/interfaces/route.interface";
const Accounting = lazy(() => import("@/pages/hp/Candidates"));
const CandidateNew = lazy(() => import("@/pages/hp/CandidateNew"));
const Settings = lazy(() => import("@/pages/hp/Settings"));
const JobsNew = lazy(() => import("@/pages/hp/JobsNew"));
// const CreateJob = lazy(() => import("@/pages/hp/CreateJobMarketing"));
// const ViewJobNew = lazy(() => import("@/pages/hp/ViewJobNew"));
const CuratedListNew = lazy(() => import("@/pages/hp/CuratedListNew"));
const NewPipeline = lazy(() => import("@/pages/hp/NewPipeline"));

const base = "hp/accounting";
const routes: Array<RouteInterface> = [
    {
        path: `${base}/candidates`,
        element: Accounting,
        isMenuItem: true,
        name: "candidate-list",
        icon: PeopleOutlineOutlined,
        props: {
            isAccounting: true,
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=3",
        },
        isVerticalRequired: true,
    },
    {
        path: `${base}/jobs`,
        element: JobsNew,
        isMenuItem: true,
        name: "jobs",
        icon: WorkOutlineOutlined,
        isVerticalRequired: false,
        props: {
            endpoint: "job/list?vertical_id=3&active=",
        },
    },
    {
        path: `${base}/candidates/:candidateId`,
        element: CandidateNew,
        props: {
            applicantUrl: "/applicant/accounting/get/",
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=3",
        },
        isVerticalRequired: true,
    },
    {
        path: `${base}/pipeline`,
        element: NewPipeline,
        isMenuItem: true,
        name: "pipeline",
        icon: RouteOutlined,
        isVerticalRequired: false,
        props: {
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=3",
            vertical: "accounting",
        },
    },
    {
        path: `${base}/curated_list`,
        element: CuratedListNew,
        isMenuItem: true,
        name: "curated_list",
        icon: ListOutlined,
        isVerticalRequired: true,
        props: {
            vertical: "accounting/",
            open_jobs_endpoint: "job/open-jobs-list/?vertical_id=3",
            jobs_endpoint: "job/list?vertical_id=3&active=true",
        },
    },
    {
        path: `${base}/settings`,
        element: Settings,
    },
    {
        path: `${base}/*`,
        element: Accounting,
        isVerticalRequired: true,
    },
];

const AccountingRoutes = routes;
export default AccountingRoutes;
