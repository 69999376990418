import { checkAndTriggerNotificationAnalytics } from "@/components/Login/utils";
import { getSetLookups } from "@/contexts/LookupsStateContext";
import { getSetUser } from "@/contexts/UserContext";
import { parse_from_path } from "@/mixpanel-tracking/utils";
import { CurrUserDetails } from "@/utils/currUserType";
import singletonLocalStorage from "@/utils/localStoring";
import {
    bootcamperNeedToContinue,
    getLookups,
    needsToFillPlacement,
} from "@/utils/utilFunctions";
import React, { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "@/../static/axios";

const RequireAuth: React.FC<{ children: React.ReactNode; isPublic?: boolean }> =
    memo(({ children, isPublic }) => {
        const location = useLocation();
        const navigate = useNavigate();
        const [tokenExpired, setTokenExpired] = React.useState(false);
        const setCurrUser = getSetUser();
        const setLookups = getSetLookups();

        React.useEffect(() => {
            if (!isPublic) {
                if (singletonLocalStorage.getItem("access_token") !== null) {
                    axiosInstance
                        .get("/user/currUser/")
                        .then((res) => {
                            const userData = {
                                ...res.data,
                                active_subscription: {
                                    ...res.data.active_subscription,
                                    verticals: [1, 2],
                                },
                            };
                            getLookups(setLookups, res.data.views !== "hp");
                            setCurrUser(userData);
                            checkAndTriggerNotificationAnalytics(
                                userData,
                                parse_from_path(location.state?.search)
                            );
                            handleRedirection(userData);
                        })
                        .catch(() => {
                            setTokenExpired(true);
                        });
                } else {
                    navigate("/", {
                        state: {
                            from: location.pathname,
                            search: location.search,
                        },
                    });
                }
            }
        }, []);

        const handleRedirection = (userData: CurrUserDetails) => {
            const from = location.pathname + location.search;
            if (bootcamperNeedToContinue(userData)) {
                navigate("/candidate/bt-placement");
            } else {
                if (
                    userData.needs_to_verify_phone &&
                    !["/change-phone", "/change-phone/"].includes(from)
                ) {
                    navigate("/change-phone");
                } else if (
                    !userData.needs_to_verify_phone &&
                    userData.signup_status === true &&
                    !["/hp/signup-wizard", "/hp/signup-wizard/"].includes(from)
                ) {
                    navigate("/hp/signup-wizard", { replace: true });
                } else if (from) {
                    navigate(from);
                } else {
                    let link = userData.views;
                    switch (userData.views) {
                        case "hp":
                            link += "/candidates";
                            break;
                        case "candidate":
                            link += "/jobs";
                            break;
                        case "fast-tracker":
                            link = "candidate/ft-vetting-process";
                    }
                    navigate(link!, {
                        replace: true,
                        state: { source: "Wrong Page - Redirected here" },
                    });
                }
                if (
                    from &&
                    !from.includes("candidate/complete-placement") &&
                    needsToFillPlacement(userData)
                ) {
                    document.getElementById(
                        "complete-ur-profile"
                    )!.style.display = "flex";
                }
            }
        };

        if (tokenExpired) {
            singletonLocalStorage.removeItem("access_token");
            navigate("?reason=token-expiry");
            return null;
        }
        return children;
    });
RequireAuth.displayName = "RequireAuth";
export default RequireAuth;
